import React from "react";
import Breadcrumb from "../components/breadcrumb";
import Layout from "../components/layout";
import SellingIdea from "../components/selling-ideas/selling-idea";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { GetComponentByName } from "../helpers/query-data";
import { graphql } from "gatsby";
import {
  GQLImageContent,
  GQLItem,
  GQLPropertiesTableContent,
  GQLQuery,
  GQLRichTextContent,
  GQLSingleLineContent,
} from "../types";
import GlobalConstants, {
  SellingIdeas as Constants,
} from "../helpers/constants";

type SellingIdeasProps = {
  data: { crystallize: GQLQuery };
};

const UseStyles = makeStyles({
  divider: {
    margin: "1rem 0 1rem 0",
  },

  subtitle: {
    margin: "0.25rem 0 0.25rem 0",
  },
});

/* Creates a JSX element representing one selling idea */
const GetSellingIdeaDetails = (
  sellingIdea: GQLItem,
  index?: number
): JSX.Element => {
  const ImageCompContent = GetComponentByName(
    sellingIdea.components ?? [],
    "image"
  ).content as GQLImageContent;
  const TitleCompContent = GetComponentByName(
    sellingIdea.components ?? [],
    "title"
  ).content as GQLSingleLineContent;
  const BodyCompContent = GetComponentByName(
    sellingIdea.components ?? [],
    "body"
  ).content as GQLRichTextContent;
  const SuggestionsCompContent = GetComponentByName(
    sellingIdea.components ?? [],
    "suggestions"
  ).content as GQLPropertiesTableContent;

  return (
    <SellingIdea
      key={index}
      image={ImageCompContent}
      title={TitleCompContent}
      body={BodyCompContent}
      suggestions={SuggestionsCompContent}
    />
  );
};

const SellingIdeas = (props: SellingIdeasProps): JSX.Element => {
  const PathObj = GetSimplePathObj(GlobalConstants.SellingIdeas);
  const Styles = UseStyles();
  const SellingIdeas = props.data.crystallize.catalogue?.children;

  return (
    <Layout currentPage="/new-selling-ideas">
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{Constants.SellingIdeasText}</Typography>
        <Typography variant="body2" className={Styles.subtitle}>
          {Constants.HeaderText}
        </Typography>
        <Divider className={Styles.divider} />
        <Typography variant="h6">{Constants.ByIndustryText}</Typography>
      </Grid>
      {SellingIdeas?.map((sellingIdea, index) =>
        GetSellingIdeaDetails(sellingIdea, index)
      )}
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(language: "en", path: "/articles/new-selling-ideas") {
        ...item
      }
    }
  }

  fragment item on CRYSTALLIZE_Item {
    children {
      name
      components {
        ...NSI_component
      }
    }
  }

  fragment NSI_component on CRYSTALLIZE_Component {
    name
    content {
      ...NSI_content
    }
  }

  fragment NSI_content on CRYSTALLIZE_ComponentContent {
    ...NSI_singleLine
    ...NSI_richText
    ...NSI_imageContent
    ...NSI_propertiesTable
  }

  fragment NSI_singleLine on CRYSTALLIZE_SingleLineContent {
    text
  }

  fragment NSI_richText on CRYSTALLIZE_RichTextContent {
    json
    html
    plainText
  }

  fragment NSI_imageContent on CRYSTALLIZE_ImageContent {
    images {
      url
      altText
    }
  }

  fragment NSI_propertiesTable on CRYSTALLIZE_PropertiesTableContent {
    sections {
      ... on CRYSTALLIZE_PropertiesTableSection {
        title
        properties {
          key
          value
        }
      }
    }
  }
`;

export default SellingIdeas;
