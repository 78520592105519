import React from "react";
import Image from "../image";
import LaunchIcon from "@material-ui/icons/Launch";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { SellingIdeas as Constants } from "../../helpers/constants";
import {
  GQLImageContent,
  GQLPropertiesTableContent,
  GQLRichTextContent,
  GQLSingleLineContent,
} from "../../types";
import { withPrefix } from "gatsby";

type SellingIdeaProps = {
  image: GQLImageContent;
  title: GQLSingleLineContent;
  body: GQLRichTextContent;
  suggestions: GQLPropertiesTableContent;
};

const UseStyles = makeStyles({
  sellingIdea: {
    textAlign: "center",
    margin: "0.5rem 0 1.5rem 0",
  },

  header: {
    fontWeight: "bold",
    margin: "0.25rem 0 0.25rem 0",
  },

  body: {
    minHeight: "5rem",
  },

  image: {
    width: "50%",
    margin: "0 auto 0 auto",
  },

  link: {
    textDecoration: "none",

    "& button": {
      fontSize: ".75rem",
      margin: "0.25rem 0 0.25rem 0",
    },
  },
});

const SellingIdea = (props: SellingIdeaProps): JSX.Element => {
  const Styles = UseStyles();

  return (
    <Grid item xs={12} md={6} lg={4} className={Styles.sellingIdea}>
      <div className={Styles.image}>
        <Image
          url={props.image?.images[0]?.url}
          altText={props.image.images[0].altText ?? ""}
        />
      </div>
      <Typography variant="body1" className={Styles.header}>
        {props.title.text ?? ""}
      </Typography>
      <Typography variant="body2" className={Styles.body}>
        {props.body.plainText}
      </Typography>
      <Typography variant="body1" className={Styles.header}>
        {Constants.OurSuggestionsText}
      </Typography>
      {props.suggestions.sections[0].properties.map((suggestion, index) => (
        /* target and rel are required to open link in a new tab/window */
        <a
          href={withPrefix(`/products${suggestion.key}`)}
          target="_blank"
          rel="noreferrer"
          key={index}
          className={Styles.link}
        >
          <Button variant="contained" color="primary">
            <LaunchIcon fontSize="small" />
            &nbsp; {suggestion.value}
          </Button>
        </a>
      ))}
    </Grid>
  );
};

export default SellingIdea;
